import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
// ---------------------------------------------------------
export default function MuiAutoCompleteDefault({
  label,
  options,
  value,
  onChange,
  disabled,
  required = false,
  disablePortal = false,
  loading,
  task,
}) {
  const [inputValue, setInputValue] = React.useState("");
  console.log(value, "va_l");
  console.log(inputValue, "inputValue");
  console.log(options, "options__");

  // ---------------------------------------------------------
  const handleChange = (newValue) => {
    console.log(newValue);
    if (newValue) {
      onChange(newValue);
    } else {
      onChange();
    }
  };

  const isCurrent = (option) => {
    return (
      option.label === task?.workspace?.name ||
      option.label === task?.project?.name ||
      option.label === task?.sectionData?.section_title
    );
  };
  const getCurrent = (option) => {
    if (option.label === task?.workspace?.name)
      return "The current task exists in this Workspace";
    if (option.label === task?.project?.name)
      return "The current task exists in this Project";
    if (option.label === task?.sectionData?.section_title)
      return "The current task exists in this Section";
    return null;
  };

  useEffect(() => {
    if (!value) {
      const defaultSection = options.find((option) => option.is_default);
      if (defaultSection) {
        onChange(defaultSection);
      }
    }
  }, [options]);

  // ---------------------------------------------------------
  return (
    <div className="copy-task">
      {loading || options.length === 0 ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "40px" }}
        >
          <CircularProgress disableShrink size={20} />
        </div>
      ) : (
        <Autocomplete
          value={value}
          disabled={disabled}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          sx={{ width: "100%" }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disablePortal={disablePortal}
          // isOptionEqualToValue={(option, value) => option.title === value.title}
          id="controllable-states-demo"
          options={options}
          renderOption={(props, option) => {
            const tooltipTitle = getCurrent(option);
            return (
              <div {...props}>
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: "85%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {option.label}
                </Typography>
                <div className="d-flex">
                  {option.is_default && (
                    <Tooltip title="Default Section">
                      <span>
                        <Box
                          sx={{
                            marginRight: "3px",
                            padding: "2px 4px",
                            color: "white",
                            fontSize: "8px",
                            fontWeight: "600",
                            backgroundColor: "primary.main",
                            borderRadius: "4px",
                          }}
                        >
                          Default
                        </Box>
                      </span>
                    </Tooltip>
                  )}
                  {isCurrent(option) && (
                    <>
                      <Tooltip title={tooltipTitle}>
                        <span>
                          <Typography
                            onClick={(event) => event.stopPropagation()}
                            sx={{
                              padding: "3px",
                              color: "white",
                              fontSize: "8px",
                              fontWeight: "600",
                              backgroundColor: "#3366FF",
                              borderRadius: "50%",
                              textTransform: "uppercase",
                              height: "18px",
                              width: "18px",
                              textAlign: "center",
                            }}
                          >
                            C
                          </Typography>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              sx={{ width: "100%" }}
              required={required}
              {...params}
              label={label}
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <>
              //       {loading ? (
              //         <CircularProgress disableShrink size={20} />
              //       ) : null}
              //     </>
              //   ),
              // }}
            />
          )}
        />
      )}
    </div>
  );
}
MuiAutoCompleteDefault.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
