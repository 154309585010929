import { get_from_localStorage, invokeApi } from "src/utils";
export const _getDashboardData = (data) => {
  const requestObj = {
    path: `api/dashboard/get_dashboard_data`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_caldendar_tasks = (data) => {
  const requestObj = {
    path: `api/dashboard/get_dashboard_calendar_data_for_web`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_time_stats = (data) => {
  const requestObj = {
    path: `api/time_stats/get_time_stats`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_active_task_list = (data) => {
  const requestObj = {
    path: `api/task/time_tracking_active_task_list`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_all_start_pause_task_list = (data) => {
  const requestObj = {
    path: `api/task/user_active_task_list`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_dashboard_favourite_projects_order = (data) => {
  const requestObj = {
    path: `api/project/update_user_project_order`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


