import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Typography, TextField, Box, MenuItem } from "@mui/material";
import Iconify from "./Iconify";

export default function MuiButtonSelectWithSearch({
  title,
  onItemClick = () => null,
  options = [],
  sx = {},
}) {
  const [open, setOpen] = useState(false);
  const [dropDownWidth, setDropDownWidth] = useState("auto");
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  console.log(options, "opts_");
  console.log(filteredOptions, "s_ opts_");

  const buttonRef = useRef(null);
  const containerRef = useRef(null);

  const handleOpen = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSearchValue("");
  };

  // const handleClick = (_option, _index) => {
  //   handleSave(_option, _index);
  // };

  const handleClick = (_option, _index) => {
    const updatedOptions = filteredOptions.map((opt, indx) =>
      indx === _index ? { ...opt, selected: opt.selected ? false : true } : opt
    );
    console.log(updatedOptions, "_updated options");
    setFilteredOptions(updatedOptions);
  };

  const handleSave = () => {
    onItemClick(filteredOptions);
    handleClose();
  };

  const handleClear = () => {
    const updatedOptions = options.map((opt) => ({ ...opt, selected: false }));
    onItemClick(updatedOptions);
    handleClose();
  };

  const selectedCount = () => {
    return options.filter((opt) => opt.selected).length;
  };

  const calculateButtonWidth = () => {
    const btn = buttonRef.current;
    if (btn) {
      const width = `${btn.clientWidth}px`;
      setDropDownWidth(width);
    }
  };

  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchValue(input);
    if (!input) {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  useEffect(() => {
    calculateButtonWidth();
    return () => setDropDownWidth("auto");
  }, [open]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <Box
      ref={containerRef}
      sx={{ position: "relative", display: "inline-block" }}
    >
      <Button
        ref={buttonRef}
        className="btn-select"
        color="inherit"
        onClick={handleOpen}
        id="multi-btn-select"
        sx={{
          bgcolor: (theme) => theme.palette.action.hover,
          alignItems: "self-end",
          ...sx,
        }}
        endIcon={
          <Iconify
            sx={{
              color: (theme) => theme.palette.primary.main,
              height: "1.4rem",
              width: "1.4rem",
            }}
            icon={
              open
                ? "ic:baseline-keyboard-arrow-up"
                : "ic:baseline-keyboard-arrow-down"
            }
          />
        }
      >
        <Typography
          sx={{
            alignSelf: "self-end",
            color: (theme) => theme.palette.primary.main,
          }}
          component="span"
          variant="subtitle2"
          fontWeight="bold"
          noWrap
        >
          {title ? title : `Title`}
          <Typography fontWeight="bold" sx={{ display: "inline" }}>
            &nbsp;:
          </Typography>
          &nbsp;
        </Typography>
        <Typography
          component="span"
          noWrap
          variant="subtitle2"
          sx={{
            color: "text.primary",
            alignSelf: "self-end",
          }}
        >
          {selectedCount()} selected
        </Typography>
      </Button>

      {/* Dropdown box under the button -------- */}
      {open && (
        <Box className="alltask-menu" sx={{ width: dropDownWidth }}>
          {/* Search --------------------------- */}
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 11,
              backgroundColor: "white",
              padding: "10px 4px 4px 4px",
            }}
          >
            <TextField
              autoFocus
              id="outlined-search"
              label="Search"
              type="text"
              // type="search"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              fullWidth
            />
          </div>

          {/* Menu Items -------------------------- */}
          {filteredOptions.map((option, index) => (
            <MenuItem
              key={index}
              selected={option.selected}
              onClick={() => handleClick(option, index)}
              sx={{ mt: 0.5 }}
            >
              <Typography variant="body2" noWrap>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
          {/* -------- Buttons */}
          <div className="menu-filter-btns">
            <Button
              fullWidth
              startIcon={<Iconify icon="codicon:list-selection" />}
              variant="contained"
              size="small"
              onClick={handleSave}
            >
              apply
            </Button>
            <Button variant="outlined" size="small" onClick={handleClear}>
              clear
            </Button>
          </div>
        </Box>
      )}
    </Box>
  );
}

MuiButtonSelectWithSearch.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      selected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  sx: PropTypes.object,
  onItemClick: PropTypes.func.isRequired,
};
