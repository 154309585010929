import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Iconify, MuiDialog, MuiDrawer, Page } from "src/components";
import {
  _cancel_subscription,
  _delete_account,
  _user_password_change,
} from "src/DAL";
import { useAppContext } from "src/hooks";
import * as Yup from "yup";
import { motion } from "framer-motion";
import {
  _send_email_reset_code,
  _verify_email_reset_code,
  _change_user_email,
} from "src/DAL";
import { MAX_PASSWORD_LIMIT, MIN_PASSWORD_LIMIT } from "src/constants";
import { get_from_localStorage, logout_user } from "src/utils";
import { _send_code_for_email_change } from "src/DAL/auth";
import moment from "moment";
import DeleteAccountDialog from "./components/DeleteAccountDialog";
const Settings = () => {
  const {
    dispatch_get_user_profile,
    dispatch_set_user_profile,
    handleDisconnectfromGoogleCalendar,
    disconnectGoogleDialogOpen,
    setDisconnectGoogleDialogOpen,
    startLoading,
    handleConnectionWithGoogleCalendar,
    googleConnectionLoading,
  } = useAppContext();
  const user = dispatch_get_user_profile();
  const navigate = useNavigate();
  const [psdOpen, setPsdOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [step, setStep] = useState(1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] =
    useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState(false);
  const [inputs, setInputs] = useState({
    // currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [newInputs, setNewInputs] = useState({
    email: "",
    oldEmail: "",
    verificationCode: "",
    newEmail: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const ResetSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(MIN_PASSWORD_LIMIT, "New Password Too Short!")
      // .max(MAX_PASSWORD_LIMIT, "New Password Too Long!")
      .notOneOf(
        [inputs.currentPassword],
        "Current Password and New Password should not be same"
      )
      .required("New Password is required"),
    // currentPassword: Yup.string().min(
    //   MIN_PASSWORD_LIMIT,
    //   "Current Password Too Short!"
    // ),
    // .max(MAX_PASSWORD_LIMIT, "Current Password Too Long!")
    // .required("Current Password is required"),
    confirmPassword: Yup.string()
      .equals(
        [inputs.newPassword],
        "New Password and Confirm Password does not match"
      )
      .required("Confirm Password is Required"),
  });
  // ---------------------------------------------------------
  const handleInputChange = (e) => {
    const { target } = e;
    setInputs({ ...inputs, [target.name]: target.value });
  };
  const handleNewInputChange = (e) => {
    const { target } = e;
    setNewInputs({ ...newInputs, [target.name]: target.value });
  };
  const handleOpenEmail = () => {
    setEmailOpen(true);
  };
  const handleCloseEmail = () => {
    setEmailOpen(false);
    setNewInputs({ oldEmail: "", verificationCode: "", newEmail: "" });
    setStep(1);
  };
  const handleOpenPsd = () => {
    setPsdOpen(true);
  };
  const handleClosePsd = () => {
    setPsdOpen(false);
    setInputs({
      // currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const handleChangePsd = async () => {
    console.log(inputs, "inputs");
    // if (inputs.currentPassword === "") {
    //   enqueueSnackbar("Current Password is required", { variant: "error" });
    //   return;
    // }
    if (inputs.newPassword === "") {
      enqueueSnackbar("New Password is required", { variant: "error" });
      return;
    }
    let invalid = false;
    await ResetSchema.validate(inputs).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    const data = {
      // old_password: inputs.currentPassword,
      new_password: inputs.newPassword,
      confirm_password: inputs.confirmPassword,
    };

    setLoading(true);
    const result = await _user_password_change(data);
    if (result.code === 200) {
      console.log(result, "res");
      setLoading(false);
      enqueueSnackbar("Password Changed Successfully", { variant: "success" });
      handleClosePsd();
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNav = () => {
    if (step === 1) {
      return;
    }
    setStep((prev) => prev - 1);
  };
  const isNavDisabled = () => {
    if (step === 1) {
      return true;
    }
    if (processing) {
      return true;
    }
    return false;
  };
  const onSendEmail = async () => {
    const EmailSchema = Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required");
    if (newInputs.email.trim() == user.email) {
      enqueueSnackbar("Please provide new email", { variant: "error" });
      return;
    }
    let invalid = false;
    await EmailSchema.validate(newInputs.email).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    const data = { email: newInputs.email.trim() };
    setProcessing(true);
    const result = await _send_code_for_email_change(data);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      setStep(2);
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    // setProcessing(true);
  };
  const handleVerifyCode = async () => {
    if (newInputs.verificationCode === "") {
      enqueueSnackbar("Code cannot be empty", { variant: "error" });
      return;
    }
    if (newInputs.email === "") {
      enqueueSnackbar("Email not provided", { variant: "error" });
      return;
    }
    const data = {
      email: user.email.trim(),
      verification_code: newInputs.verificationCode.trim(),
    };
    setProcessing(true);
    const result = await _verify_email_reset_code(data);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      // setStep(3);
      handleChangeEmail();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    //
  };
  const handleChangeEmail = async () => {
    const EmailSchema = Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required");

    let invalid = false;
    await EmailSchema.validate(newInputs.email).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }

    const data = { email: newInputs.email.trim() };
    setProcessing(true);
    const result = await _change_user_email(data);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Your Email Has Been Changed Successfully", {
        variant: "success",
      });
      dispatch_set_user_profile({ ...user, email: data.email });
      handleCloseEmail();
      setNewInputs({ oldEmail: "", verificationCode: "", newEmail: "" });
      setStep(1);
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDeleteAccount = async (password) => {
    const req_data = {
      password: password.toLowerCase(),
    }
    try {
      setDeleteAccountLoading(true);
      const result = await _delete_account(user.user_id, req_data);
      if (result.code == 200) {
        enqueueSnackbar("Account deleted successfully", { variant: "success" });
        setDeleteDialogOpen(false);
        logout_user();
        // navigate("/");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while deleting the account");
    } finally {
      setDeleteAccountLoading(false);
    }
  };
  const handleCancelSubscription = async () => {
    try {
      setCancelSubscriptionLoading(true);
      const result = await _cancel_subscription();
      if (result.code == 200) {
        setCancelSubscriptionDialogOpen(false);
        enqueueSnackbar("Subscription Canceled successfully", {
          variant: "success",
        });
        dispatch_set_user_profile({
          ...dispatch_get_user_profile(),
          is_cancle_set: true,
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while cancel subscription");
    } finally {
      setCancelSubscriptionLoading(false);
    }
  };
  // ---------------------------------------------------------
  return (
    <Page title="Settings">
      <Container maxWidth="xl">
        <div className="mb-4">
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </div>
        <Container maxWidth="sm" sx={{ px: { xs: 0, sm: 1 } }}>
          <Card>
            <CardContent>
              <Stack justifyContent="center" spacing={3}>
                <Stack
                  spacing={1}
                  justifyContent="space-between"
                  direction={"row"}
                >
                  <Stack spacing={1}>
                    <Typography fontWeight="bold" variant="body1">
                      Email
                    </Typography>
                    <Typography variant="body1">{user.email}</Typography>
                  </Stack>

                  <div className="centered-row">
                    <Button
                      sx={{ minWidth: "100px" }}
                      onClick={handleOpenEmail}
                      size="small"
                      variant="contained"
                    >
                      Change
                    </Button>
                  </div>
                </Stack>
                <Stack
                  spacing={1}
                  justifyContent="space-between"
                  direction={"row"}
                >
                  <Stack spacing={1}>
                    <Typography fontWeight="bold" variant="body1">
                      Password
                    </Typography>
                    <Typography variant="body1">Update Password </Typography>
                  </Stack>
                  <div className="centered-row">
                    <Button
                      sx={{ minWidth: "100px" }}
                      onClick={handleOpenPsd}
                      size="small"
                      variant="contained"
                    >
                      Update
                    </Button>
                  </div>
                </Stack>
                {/* <Stack spacing={1}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Iconify icon="devicon:google" />
                    <Typography fontWeight="bold" variant="body1">
                      Connect with Google
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">
                      {user.google_account_email
                        ? `Your tasks are syncronized with google account ${
                            user.google_account_email
                          }`
                        : "Syncronize your tasks with Google calendar"}
                    </Typography>
                    <div className="centered-row">
                      {!user.google_account_email && (
                        <LoadingButton
                          sx={{ minWidth: "100px" }}
                          size="small"
                          variant="contained"
                          loading={googleConnectionLoading}
                          onClick={() => {
                            startLoading();
                            handleConnectionWithGoogleCalendar();
                          }}
                        >
                          Connect
                        </LoadingButton>
                      )}
                      {user.google_account_email && (
                        <Button
                          sx={{ minWidth: "100px" }}
                          size="small"
                          variant="contained"
                          onClick={() => setDisconnectGoogleDialogOpen(true)}
                        >
                          Disconnect
                        </Button>
                      )}
                    </div>
                  </Stack>
                </Stack> */}
                {/* <Stack
                  spacing={1}
                  justifyContent="space-between"
                  direction={"row"}
                >
                  <Stack spacing={1}>
                    <Typography fontWeight="bold" variant="body1">
                      Current Plan
                    </Typography>
                    <Typography variant="body1">
                      {user.subscription
                        ? user.subscription
                        : "Free Plan (New User)"}
                    </Typography>
                  </Stack>
                  <div className="centered-row">
                    <Button
                      sx={{ minWidth: "100px" }}
                      disabled={user.subscription_plan_name == "advance"}
                      onClick={() => {
                        window.open(
                          `${
                            window.location.origin
                          }/plansLink?token=${get_from_localStorage("token")}`
                        );
                      }}
                      size="small"
                      variant="contained"
                    >
                      Upgrade
                    </Button>
                  </div>
                </Stack> */}
                {/* {user.subscription_plan_name !== "free" && (
                  <Stack
                    spacing={1}
                    justifyContent="space-between"
                    direction={"row"}
                  >
                    <Stack spacing={1}>
                      <Typography fontWeight="bold" variant="body1">
                        Cancel Subscription
                      </Typography>
                      {!user.is_cancle_set && (
                        <Typography variant="body1">
                          This action will block access to all your workspaces
                        </Typography>
                      )}
                      {user.is_cancle_set && (
                        <Typography variant="body1">
                          Your subscription has been cancelled, your access will
                          be revoked on{" "}
                          {moment(
                            moment(user.subscription_date).add(1, "month")
                          ).format("DD-MMM-YYYY")}
                        </Typography>
                      )}
                    </Stack>
                    <div className="centered-row">
                      <Button
                        sx={{ minWidth: "100px" }}
                        disabled={user.is_cancle_set}
                        color="error"
                        onClick={() => setCancelSubscriptionDialogOpen(true)}
                        size="small"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Stack>
                )} */}
                {/* <Stack
                  spacing={1}
                  justifyContent="space-between"
                  direction={"row"}
                >
                  <Stack spacing={1}>
                    <Typography fontWeight="bold" variant="body1">
                      Payment History
                    </Typography>
                    <Typography variant="body1">
                      View Transactions History
                    </Typography>
                  </Stack>
                  <div className="centered-row">
                    <Button
                      sx={{ minWidth: "100px" }}
                      onClick={() => {
                        navigate("/payment-history");
                      }}
                      size="small"
                      variant="contained"
                    >
                      View
                    </Button>
                  </div>
                </Stack> */}
                <Stack
                  spacing={1}
                  justifyContent="space-between"
                  direction={"row"}
                >
                  <Stack spacing={1}>
                    <Typography fontWeight="bold" variant="body1">
                      Delete Account
                    </Typography>
                    <Typography variant="body1">
                      Delete this account, this action is irreversable. All your
                      tasks and chats will be deleted.
                    </Typography>
                  </Stack>
                  <div className="centered-row">
                    <Button
                      sx={{ minWidth: "100px" }}
                      color="error"
                      onClick={() => setDeleteDialogOpen(true)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </div>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Link style={{ color: "black" }} to="/terms-and-conditions">
                    Terms and Conditions
                  </Link>
                  <Typography>/</Typography>
                  <Link style={{ color: "black" }} to="/privacy-policy">
                    Privacy policy
                  </Link>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Container>
        <MuiDrawer
          title="Change Email"
          onClose={handleCloseEmail}
          isOpen={emailOpen}
        >
          {step !== 1 && (
            <IconButton
              disabled={isNavDisabled()}
              sx={{ mb: 1 }}
              onClick={handleNav}
            >
              <Iconify icon="ep:back" />
            </IconButton>
          )}
          {step === 1 && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                opacity: 1,
                scale: 1,
                animationDuration: 1,
                animationDelay: 1,
              }}
              transition={{ type: "tween" }}
            >
              <Stack spacing={3}>
                {/* step 1 */}
                <Stack spacing={1.5}>
                  <Typography>Please enter new email</Typography>
                  <TextField
                    variant="outlined"
                    label="Email"
                    name="email"
                    type="email"
                    value={newInputs.email}
                    onChange={handleNewInputChange}
                  />
                </Stack>
                <div className="centered-row">
                  <LoadingButton
                    loading={processing}
                    onClick={onSendEmail}
                    variant="contained"
                  >
                    Send
                  </LoadingButton>
                </div>
              </Stack>
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                opacity: 1,
                scale: 1,
                animationDuration: 1,
                animationDelay: 1,
              }}
              transition={{ type: "tween" }}
            >
              <Stack spacing={3}>
                {/* step 2*/}
                <Stack spacing={1.5}>
                  <Typography>
                    Please enter the code you received on your email
                  </Typography>
                  <TextField
                    variant="outlined"
                    label="Verification Code"
                    name="verificationCode"
                    type="text"
                    value={newInputs.verificationCode}
                    onChange={handleNewInputChange}
                  />
                </Stack>
                <div className="centered-row">
                  <LoadingButton
                    loading={processing}
                    onClick={handleVerifyCode}
                    variant="contained"
                  >
                    Verify
                  </LoadingButton>
                </div>
              </Stack>
            </motion.div>
          )}
          {step === 3 && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                opacity: 1,
                scale: 1,
                animationDuration: 1,
                animationDelay: 1,
              }}
              transition={{ type: "tween" }}
            >
              <Stack spacing={3}>
                {/* step 3 */}
                <Stack spacing={1.5}>
                  <Typography>Please enter new email</Typography>
                  <TextField
                    value={newInputs.newEmail}
                    onChange={handleNewInputChange}
                    variant="outlined"
                    label="New Email"
                    name="newEmail"
                    type="email"
                  />
                </Stack>
                <div className="centered-row">
                  <LoadingButton
                    loading={processing}
                    onClick={handleChangeEmail}
                    variant="contained"
                  >
                    Change
                  </LoadingButton>
                </div>
              </Stack>
            </motion.div>
          )}
        </MuiDrawer>
        <MuiDrawer
          title="Change Password"
          onClose={handleClosePsd}
          isOpen={psdOpen}
        >
          <Stack spacing={3}>
            {/* <TextField
              tabIndex="-1"
              value={inputs.currentPassword}
              onChange={handleInputChange}
              required
              name="currentPassword"
              fullWidth
              autoComplete="current-password"
              type={showPassword1 ? "text" : "password"}
              label="Current Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={4}
                      edge="end"
                      onClick={() => setShowPassword1((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword1 ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
            <TextField
              tabIndex="2"
              value={inputs.newPassword}
              onChange={handleInputChange}
              required
              name="newPassword"
              fullWidth
              autoComplete="current-password"
              type={showPassword2 ? "text" : "password"}
              label="New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={4}
                      edge="end"
                      onClick={() => setShowPassword2((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword2 ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              tabIndex="3"
              value={inputs.confirmPassword}
              onChange={handleInputChange}
              required
              name="confirmPassword"
              fullWidth
              autoComplete="current-password"
              type={showPassword3 ? "text" : "password"}
              label="Confirm Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={4}
                      edge="end"
                      onClick={() => setShowPassword3((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword3 ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="centered-row">
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={handleChangePsd}
              >
                Change
              </LoadingButton>
            </div>
          </Stack>
        </MuiDrawer>
        <DeleteAccountDialog
          loading={deleteAccountLoading}
          open={deleteDialogOpen}
          onAgree={handleDeleteAccount}
          onToggle={(val) => setDeleteDialogOpen(val)}
          user={user}
        />
        <MuiDialog
          title="Disconnect from Google Calendar"
          message={`Are you sure you want to disconnect the google account "${user.google_account_email}" ?`}
          open={disconnectGoogleDialogOpen}
          onToggle={(val) => setDisconnectGoogleDialogOpen(val)}
          loading={googleConnectionLoading}
          onAgree={handleDisconnectfromGoogleCalendar}
        />
        <MuiDialog
          open={cancelSubscriptionDialogOpen}
          onToggle={(val) => setCancelSubscriptionDialogOpen(val)}
          loading={cancelSubscriptionLoading}
          title="Cancel Subscription"
          message="Are you sure you want to cancel your subscription?"
          onAgree={handleCancelSubscription}
        />
      </Container>
    </Page>
  );
};

export default Settings;
