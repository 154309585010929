import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Iconify } from "src/components";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "./ConfirmDelete";

export default function DeleteAccountDialog({
  open,
  onToggle,
  onAgree,
  loading = false,
  user,
}) {
  const navigate = useNavigate();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleClose = () => {
    if (!loading) {
      onToggle(false);
    }
  };

  const openDeleteConfirm = () => {
    setOpenConfirmDelete(true);
  };
  const closeDeleteConfirm = () => {
    setOpenConfirmDelete(false);
    handleClose();
  };

  const handleNavigate = () => {
    navigate("/support?openDrawer=true");
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        // PaperProps={{
        //   sx: {
        //     minHeight: "200px",
        //   },
        // }}
      >
        <DialogTitle>{user?.first_name} We're sorry to see you go!</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography>
              Deleting your account will remove all your data, including your
              Teams, Clients, Workspaces, Projects, and Tasks permanently.
            </Typography>
            <Typography>
              If you need assistance, please don't hesitate to
              <span style={{ fontWeight: 600, color:"#00AB55", padding: "0px 8px" }}>
                <Iconify fontSize="16px" icon="bx:support" marginBottom="2px" />{" "}
                CONTACT SUPPORT
              </span>
              Our team will reach out to you within next 24 hours.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={openDeleteConfirm}>
            Delete Account
          </Button>
          <Button
            variant="contained"
            onClick={handleNavigate}
            startIcon={<Iconify fontSize="16px" icon="bx:support" />}
          >
            Contact Support
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirm Delete Dialog  --------- */}
      <ConfirmDelete
        open={openConfirmDelete}
        onAgree={onAgree}
        onClose={closeDeleteConfirm}
        loading={loading}
        closeDeleteDialog={handleClose}
      />
    </>
  );
}

DeleteAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
