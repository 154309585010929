import React, { useEffect, useState } from "react";
import {
  Switch,
  Stack,
  Typography,
  Paper,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Iconify, MuiDrawer } from "src/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { _update_section_order } from "src/DAL";
import { _project_sections_all_list } from "src/DAL/project";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { _update_section_order_list } from "src/DAL/sections";

// ===============================================================
export default function ReorderProjectsDrawer({
  // settingsAndStatsOpen,
  // closeSettingsAndStats,
  reorderProjectsOpen,
  closeReorderDrawer,
  tempData,

  // workspaceId,
  // slug,
  // getFilteredSections,
}) {
  const [fullList, setFullList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  console.log(fullList, "__proList");
  // Get all sections List API ------------------------------------
  const getAllSectionsList = async () => {
    setLoading(true);
    const req_data = {
      workspace_id: workspaceId,
      list_type: "0",
    };
    const response = await _project_sections_all_list(req_data, slug);
    if (response.code === 200) {
      const sectionsList = response.project_list.section_lists;
      sectionsList.sort((a, b) => a.order - b.order);
      setFullList({ section_lists: sectionsList });
      //   setFullList((prevData) => ({
      //     ...prevData,
      //     section_lists: sectionsList,
      //   }));
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  // Drag and Drop ------------------------
  // const onDragSectionEnd = (result) => {
  //   const { destination, source } = result;
  //   if (!destination) return;

  //   const updatedList = [...fullList.section_lists];
  //   const [movedItem] = updatedList.splice(source.index, 1);
  //   updatedList.splice(destination.index, 0, movedItem);

  //   const reorderedSections = updatedList.map((section, index) => ({
  //     ...section,
  //     order: index,
  //   }));
  //   console.log(reorderedSections, "reordered_Sections_Drag_Drop_locally");
  //   setFullList((prevData) => ({
  //     ...prevData,
  //     section_lists: reorderedSections,
  //   }));

  //   // const req_data = {
  //   //   section_id: result.draggableId,
  //   //   list_type: "0",
  //   //   order: result.destination.index,
  //   // };
  //   // updateSectionOrder(req_data);
  // };
  const onDragSectionEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const updatedList = [...fullList];
    const [movedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, movedItem);
    const reorderedList = updatedList.map((item, index) => ({
      ...item,
      order: index,
    }));

    setFullList(reorderedList);
    console.log("Reordered list:", reorderedList);
  };

  // Move up/down ------------------------------------------------
  const onMoveUpDown = (type, section) => {
    const updatedList = [...fullList.section_lists];
    const currentIndex = updatedList.findIndex(
      (item) => item._id === section._id
    );
    const newIndex = type === "up" ? currentIndex - 1 : currentIndex + 1;
    // Swap the elements
    [updatedList[currentIndex], updatedList[newIndex]] = [
      updatedList[newIndex],
      updatedList[currentIndex],
    ];
    const reorderedSections = updatedList.map((section, index) => ({
      ...section,
      order: index,
    }));

    setFullList({
      ...fullList,
      section_lists: reorderedSections,
    });

    // const req_data = {
    //   section_id: section._id,
    //   list_type: "0",
    //   order: newIndex,
    // };
    // updateSectionOrder(req_data);
  };

  // handlesaveorder send req_data to api  ----------------------------
  const handleSaveOrder = () => {
    const sectionId = fullList.section_lists.map((section) => section._id);
    const projectId = fullList.section_lists[0]?.project.id;

    const req_data = {
      section_ids: sectionId,
      project_id: projectId,
    };
    console.log(req_data, "req_data");
    updateSectionOrder(req_data);
    // getFilteredSections(null, null, true);
  };

  // Update section order API ----------------------------------------
  const updateSectionOrder = async (req_data) => {
    setOrderLoading(true);
    const response = await _update_section_order_list(req_data, slug);
    if (response.code === 200) {
      // getFilteredSections(null, null, true);
      closeReorderDrawer();
      enqueueSnackbar("Section Order Updated Sucessfully!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setOrderLoading(false);
  };

  // useEffects =====================================================
  useEffect(() => {
    if (reorderProjectsOpen) {
      // getAllSectionsList();
      setFullList(tempData);
    }
  }, [reorderProjectsOpen]);

  return (
    <>
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 340 } }}
        isOpen={reorderProjectsOpen}
        onClose={closeReorderDrawer}
        title={
          <Typography sx={{ fontWeight: "700" }}>Reorder Projects</Typography>
        }
        bottomComponent={
          <Stack direction="row" spacing={1} sx={{ px: "20px", py: "10px" }}>
            <LoadingButton
              loading={orderLoading}
              size="large"
              variant="contained"
              className="outline-btn"
              onClick={handleSaveOrder}
              startIcon={<Iconify icon="ix:reorder" />}
              fullWidth
            >
              Update order
            </LoadingButton>
            <LoadingButton
              size="large"
              variant="outlined"
              className="outline-btn"
              onClick={closeReorderDrawer}
              // startIcon={<Iconify icon="tdesign:rollback" />}
            >
              Discard
            </LoadingButton>
          </Stack>
        }
      >
        {/* <hr className="divider" /> */}
        {/* Reorder_Sections ======================================== */}
        <Stack spacing={2}>
          {/* <Typography sx={{ fontWeight: "700" }}>Reorder Projects</Typography> */}
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="300px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <DragDropContext onDragEnd={onDragSectionEnd}>
              <Droppable droppableId="sectionsList">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {fullList?.map((section, index) => (
                      <Draggable
                        key={section._id || index}
                        draggableId={section._id || index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Paper
                            square
                            elevation={2}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              // borderLeft: section.is_default
                              //   ? `4px solid`
                              //   : `4px solid transparent`,
                              // borderLeftColor: section.is_default
                              //   ? "primary.main"
                              //   : "transparent",
                              marginBottom: "10px",
                              padding: "0px 5px 0px 5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: snapshot.isDragging
                                ? "#ebf8f2"
                                : "white ",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: snapshot.isDragging
                                  ? "primary.main"
                                  : "text.primary",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                // maxWidth: "75%",
                              }}
                            >
                              <span className="index-count">{index + 1}.</span>
                              {section.title}
                            </Typography>

                            <div className="d-flex align-items-center">
                              {section.is_favorite === true && (
                                <>
                                  <Iconify
                                    sx={{ color: "#dbb90f" }}
                                    fontSize="16px"
                                    icon="material-symbols:star" 
                                  />
                                </>
                              )}

                              <Tooltip title="Move Up">
                                <span>
                                  <IconButton
                                  // onClick={() => onMoveUpDown("up", section)}
                                  // disabled={index === 0}
                                  >
                                    <ArrowUpwardIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>

                              <Tooltip title="Move Down">
                                <span>
                                  <IconButton
                                  // onClick={() =>
                                  //   onMoveUpDown("down", section)
                                  // }
                                  // disabled={
                                  //   index ===
                                  //   fullList.section_lists.length - 1
                                  // }
                                  >
                                    <ArrowDownwardIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Stack>
      </MuiDrawer>
    </>
  );
}
