import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Iconify from "src/components/Iconify";
import CheckListDialog from "./CheckListDialog";
import { LoadingButton } from "@mui/lab";
import CheckListTeamPickerButton from "src/components/task-components/CheckListTeamPickerButton";
import CheckListPriorityPopover from "src/components/task-components/CheckListPriorityPopover";
import {
  _edit_checklist_target_date,
  _update_check_item_task_priority,
  _update_checklist_assignee,
  _update_checklist_item_priority,
} from "src/DAL/tasks";
import { useSnackbar } from "notistack";
import CheckListTargetDate from "src/components/task-components/CheckListTargetDate";
import { MuiDialog } from "src/components";
import {
  _remove_task_checklist,
  _update_task_checklist_item,
} from "src/DAL/taskDetailDrawer";

function CheckListItem({
  checklist,
  canEditTask,
  setEditorOpen,
  task,
  setTask,
  setTasks,
  getTaskDetail,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editChecklistItem, setEditChecklistItem] = useState("");
  const [newChecklistItem, setNewChecklistItem] = useState("");
  const [addingChecklistItem, setAddingChecklistItem] = useState("");
  // loading states ---------------------------------
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const [isDeletingCheckList, setIsDeletingCheckList] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingCheckBox, setLoadingCheckBox] = useState(null);
  // ------------------------------------------------
  const [deleteChecklistDialogOpen, setDeleteChecklistDialogOpen] =
    useState(false);
  const [targetChecklist, setTargetChecklist] = useState();
  const [targetChecklistItem, setTargetChecklistItem] = useState();
  const [deletechecklistitemDialog, setDeletechecklistitemDialog] =
    useState(false);

  const deleteChecklistItem = () => {
    setIsDeletingItem(true);
    let req_obj = {
      task_id: task?._id,
      list_id: targetChecklist._id,
      list_item_array: targetChecklist.items
        .filter((val) => val._id !== targetChecklistItem._id)
        .map((item) => {
          delete item._id;
          return item;
        }),
    };
    updateChecklistItems(req_obj);
  };
  const openChecklistItemDeleteDialog = () => {
    setDeletechecklistitemDialog(true);
  };
  const closeChecklistItemDeleteDialog = () => {
    setDeletechecklistitemDialog(false);
    setIsDeletingItem(false);
    setTargetChecklistItem();
  };

  const openDeleteChecklistDialog = (checklist) => {
    setTargetChecklist(checklist);
    setDeleteChecklistDialogOpen(true);
  };
  const closeDeleteChecklistDialog = () => {
    setDeleteChecklistDialogOpen(false);
    setIsDeletingCheckList(false);
    setTargetChecklist();
  };

  const deleteCheckList = async () => {
    setIsDeletingCheckList(true);
    try {
      const req_obj = {
        task_id: task?._id,
        list_id: targetChecklist._id,
      };
      const result = await _remove_task_checklist(req_obj);
      if (result.code == 200) {
        setTask(result.task_data);
        closeDeleteChecklistDialog();
        getTaskDetail();
        setTasks(task?.section_id, task?._id, result.task_data);
        enqueueSnackbar("CheckList Deleted Successfully", {
          variant: "success",
        });
        setIsDeletingCheckList(false);
      }
    } catch (error) {
      console.log(error);
      setIsDeletingCheckList(false);
    }
  };

  const updateChecklistItems = async (req_obj) => {
    const result = await _update_task_checklist_item(req_obj);
    if (result.code == 200) {
      setLoading(false);
      setLoadingCheckBox(null);
      setNewChecklistItem("");
      setLoadingEdit(false);
      setIsDeletingItem(false);
      setTask(result.task_data);
      setTargetChecklist((prev) =>
        result.task_data.check_list.find((list) => list._id == prev._id)
      );
      setTasks(task?.section_id, task?._id, result.task_data);
      getTaskDetail();
      closeChecklistItemDeleteDialog();
    }
  };

  const handleOpenDialog = (item) => {
    setSelectedChecklist(item);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedChecklist(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------ edit checklist & checkbox
  const editChecked = (list, currentItem, updatedItem) => {
    setTargetChecklist(list);
    setLoadingEdit(true);
    setLoadingCheckBox(currentItem._id);
    let req_obj = {
      task_id: task?._id,
      list_id: list._id,
      list_item_array: list.items.map((item) => {
        if (currentItem._id == item._id) {
          delete updatedItem._id;
          return updatedItem;
        } else {
          return { name: item.name, status: item.status };
        }
      }),
    };
    updateChecklistItems(req_obj);
  };

  // ----------------- Add new checklist item
  const handleSaveCheckListItem = async () => {
    try {
      if (!newChecklistItem) {
        return;
      }
      setLoading(true);
      const req_obj = {
        task_id: task?._id,
        list_id: targetChecklist._id,
        list_item_array: [
          ...targetChecklist.items.map((item) => {
            return { name: item.name, status: item.status };
          }),
          { name: newChecklistItem, status: false },
        ],
      };
      await updateChecklistItems(req_obj);
    } catch (error) {
      console.log(error);
    }
  };
  // --------------------------- change priority
  const handleChangePriority = async (data) => {
    const req_data = {
      task_id: task._id,
      list_id: selectedChecklist.checklistId,
      priority: data.value,
      list_item_id: selectedChecklist.itemId,
    };
    const result = await _update_checklist_item_priority(req_data);
    if (result.code === 200) {
      enqueueSnackbar("Task Priority Updated Successfully", {
        variant: "success",
      });
      getTaskDetail();
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };

  // --------------------------- change member
  const handleChangeMembers = async (data) => {
    console.log(data, "req_data change-team");
    const { targetUser, checklistId, itemId, currectAssignes } = data;

    console.log(data, "Check Data");
    let assignedUserArr = [...currectAssignes];
    let index = assignedUserArr.findIndex((x) => x == targetUser?.user_id);
    if (index == -1) {
      assignedUserArr.push(targetUser?.user_id);
    } else if (index > -1) {
      assignedUserArr.splice(index, 1);
    }

    console.log(assignedUserArr, "Check Data assignedUserArr");

    const req_data = {
      assigned_to: assignedUserArr,
      task_id: task._id,
      list_id: checklistId,
      list_item_id: itemId,
    };
    console.log(req_data, "req_data");

    const result = await _update_checklist_assignee(req_data);

    if (result.code === 200) {
      enqueueSnackbar("Team updated successfully", { variant: "success" });
      getTaskDetail();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // --------------------------- change target date
  const handleChangeDate = async (data) => {
    console.log(data, "change-date");
    const { checklistId, targetDate } = data;
    const req_data = {
      task_id: task._id,
      list_id: checklistId,
      target_date: targetDate,
    };
    const result = await _edit_checklist_target_date(req_data);

    if (result.code === 200) {
      getTaskDetail();
      console.log(result, "res");
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };

  //---------------------------------------

  const totalItems = checklist?.items.length;
  const completedItems = checklist?.items.filter((item) => item.status).length;
  const percentage = totalItems > 0 ? (completedItems / totalItems) * 100 : 0;

  return (
    <>
      <Grid
        className="checklist"
        container
        spacing={1}
        key={checklist._id}
        mt={0.5}
      >
        <Grid item xs={1}>
          <div
            className="d-flex align-items-center justify-content-end pe-1"
            style={{ padding: "4px" }}
          >
            <Iconify
              icon="tabler:checkbox"
              sx={{ height: "17px", width: "17px" }}
            />
          </div>
        </Grid>
        <Grid item xs={11}>
          <div className="d-flex justify-content-between align-items-center">
            <h5
              style={{
                fontSize: "14px",
                margin: "0px",
                maxWidth:"50%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {checklist.name}
            </h5>

            <div className="d-flex align-items-center">
              {checklist.items.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid #919eab3d",
                    paddingRight: "7px",
                  }}
                >
                  {completedItems === totalItems && (
                    <div className="d-flex align-items-center gap-1">
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontWeight: 400,
                          fontSize: "12px",
                          marginTop: "3px",
                        }}
                      >
                        <span className="px-1 fw-bold">
                          {completedItems} / {totalItems}
                        </span>
                        Well done!
                      </Typography>
                      <Iconify
                        color="primary.main"
                        fontSize="18px"
                        icon="icon-park-twotone:thumbs-up"
                      />
                    </div>
                  )}
                  {completedItems !== totalItems && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        {completedItems}
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: 0.5,
                          marginRight: 0.75,
                          fontSize: "12px",
                        }}
                      >
                        / {totalItems}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Completed
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              {/* Target date ------------------------------- */}
              <div
                style={{
                  borderRight: "1px solid #919eab3d",
                  paddingRight: "7px",
                  paddingLeft: "7px",
                }}
              >
                <CheckListTargetDate
                  checklist={checklist}
                  onDateChange={handleChangeDate}
                  disabled={canEditTask}
                  completed={completedItems === totalItems}
                />
              </div>

              {/* Menu  -----------------------------------------*/}
              <IconButton size="small" onClick={handleClick}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {canEditTask && (
                  <MenuItem
                    sx={{ color: "#b72136" }}
                    onClick={() => {
                      handleClose();
                      openDeleteChecklistDialog(checklist);
                    }}
                  >
                    <Iconify
                      icon={"fluent:delete-24-regular"}
                      sx={{ height: "14px", width: "14px", mr: 1 }}
                    />
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </Grid>
        {/* Progress bar ------------------------- */}
        <Box sx={{ width: "100%", margin: "5px 0px 5px 62px" }}>
          <Box
            sx={{
              width: "100%",
              height: 5,
              backgroundColor: "#c5ead7",
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: `${percentage}%`,
                height: "100%",
                backgroundColor: "primary.main",
                transition: "width 0.3s ease-in-out",
              }}
            />
          </Box>
        </Box>

        {checklist.items.map((item) => (
          <Grid
            item
            xs={12}
            key={item._id}
            spacing={0}
            style={{ paddingTop: "0px", marginLeft: "2px" }}
          >
            <div className="d-flex" style={{ margin: "3px 0px 3px 51px" }}>
              {editChecklistItem !== item._id && (
                <div
                  className="d-flex align-items-center justify-content-between check-list-item w-100"
                  style={{ backgroundColor: "#F5F8FA" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "70%" }}
                  >
                    {/* checkbox  --------------*/}
                    {loadingCheckBox === item._id ? (
                      <div className="checkbox-loader-div">
                        <CircularProgress size={15} />
                      </div>
                    ) : (
                      <Checkbox
                        checked={item.status}
                        size="small"
                        padding="4px"
                        onChange={(e) =>
                          editChecked(checklist, item, {
                            ...item,
                            status: e.target.checked,
                          })
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditChecklistItem("");
                          setEditorOpen(false);
                        }}
                      />
                    )}

                    <Typography
                      onClick={() => handleOpenDialog(item)}
                      className="item-name"
                    >
                      {item.name}
                    </Typography>
                  </div>
                  {/* ------------------------------ Buttons List */}
                  {canEditTask && (
                    <div className="gap-1 d-flex align-items-center">
                      <CheckListTeamPickerButton
                        taskTeam={task?.team}
                        allowOpen={canEditTask}
                        setSelectedChecklist={setSelectedChecklist}
                        checklist={checklist}
                        item={item}
                        onChange={handleChangeMembers}
                      />
                      <CheckListPriorityPopover
                        allowOpen={true}
                        value={item.priority}
                        item={item}
                        checklist={checklist}
                        setSelectedChecklist={setSelectedChecklist}
                        onChange={handleChangePriority}
                      />
                      <IconButton
                        onClick={(e) => {
                          if (canEditTask) {
                            e.stopPropagation();
                            setAddingChecklistItem("");
                            setTargetChecklist(checklist);
                            setNewChecklistItem(item.name);
                            setTargetChecklistItem(item);
                            setEditChecklistItem(item._id);
                          }
                        }}
                      >
                        <Iconify
                          icon="akar-icons:edit"
                          sx={{ height: "16px", width: "16px" }}
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setTargetChecklistItem(item);
                          setTargetChecklist(checklist);
                          openChecklistItemDeleteDialog();
                        }}
                        // size="small"
                      >
                        <Iconify
                          className="delete-icon"
                          icon={"fluent:delete-24-regular"}
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              )}
              {/* Edit checklist item ---------------------- */}
              {editChecklistItem == item._id && (
                <div className="w-100 p-2 edit-checklist-div">
                  <textarea
                    className="checklist-item-title w-100"
                    autoFocus
                    value={newChecklistItem}
                    onChange={(e) => setNewChecklistItem(e.target.value)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditorOpen(false);
                      setAddingChecklistItem("");
                    }}
                    onFocus={(e) => {
                      const value = e.target.value;
                      e.target.setSelectionRange(value.length, value.length); //Move cursor to last : Shamroz
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        editChecked(checklist, item, {
                          ...item,
                          name: newChecklistItem,
                        });
                      }
                    }}
                  />
                  <div className="d-flex align-items-center gap-1 ">
                    <LoadingButton
                      loading={loadingEdit}
                      size="small"
                      variant="contained"
                      style={{ height: "auto" }}
                      onClick={(e) => {
                        editChecked(checklist, item, {
                          ...item,
                          name: newChecklistItem,
                        });
                      }}
                      disabled={!newChecklistItem}
                    >
                      Save
                    </LoadingButton>
                    <Button
                      size="small"
                      variant="inherit"
                      style={{ height: "auto" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditChecklistItem("");
                        // setLoading(false);
                      }}
                    >
                      Discard
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        ))}

        {/* ------------------------------------------------------------------------------ */}
        <Grid xs={12} sx={{ pt: 0 }}>
          {addingChecklistItem !== checklist._id && canEditTask && (
            <div className="d-flex">
              <Typography
                className="add-check-list-btn"
                color="inherit"
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  setNewChecklistItem("");
                  setEditChecklistItem("");
                  setTargetChecklist(checklist);
                  setAddingChecklistItem(checklist._id);
                  setEditorOpen(false);
                }}
              >
                Add New Item
                <Iconify
                  fontSize="16px"
                  marginLeft="5px"
                  icon="mingcute:add-fill"
                />
              </Typography>
            </div>
          )}
          {addingChecklistItem == checklist._id && (
            <div className="add-new-check-item">
              <textarea
                className="checklist-item-title"
                autoFocus
                placeholder="Add checklist item"
                value={newChecklistItem}
                onChange={(e) => setNewChecklistItem(e.target.value)}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditorOpen(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && newChecklistItem.trim() !== "") {
                    e.preventDefault(); 
                    handleSaveCheckListItem(); 
                    setNewChecklistItem(""); 
                  }
                }}
              />
              <div>
                <LoadingButton
                  size="small"
                  variant="contained"
                  loading={loading}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSaveCheckListItem();
                    setEditorOpen(false);
                  }}
                  disabled={!newChecklistItem}
                >
                  Save
                </LoadingButton>
                <Button
                  sx={{ ml: 1 }}
                  size="small"
                  variant="inherit"
                  onClick={() => {
                    setAddingChecklistItem("");
                    setNewChecklistItem("");
                    setEditChecklistItem("");
                    setLoading(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Grid>

        <CheckListDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          item={selectedChecklist}
          title={checklist.name}
        />
        <MuiDialog
          loading={isDeletingCheckList}
          open={deleteChecklistDialogOpen}
          onToggle={closeDeleteChecklistDialog}
          onAgree={deleteCheckList}
          title="Delete Checklist including all its items"
          itemName={targetChecklist?.name}
          message="Are you sure you want to delete this checklist?"
        />

        <MuiDialog
          loading={isDeletingItem}
          open={deletechecklistitemDialog}
          onToggle={closeChecklistItemDeleteDialog}
          onAgree={deleteChecklistItem}
          title="Delete Checklist Item"
          itemName={targetChecklistItem?.name}
          message="Are you sure you want to delete this item from checklist?"
        />
      </Grid>
      {/* ---------------------------------------------------------------- */}
      <Divider className="divider-li" component="li" />
      {/* ---------------------------------------------------------------- */}
    </>
  );
}

export default CheckListItem;
