import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Iconify } from "src/components";

const ConfirmDelete = ({ open, onAgree, onClose, loading }) => {
  const [confirmationInput, setConfirmationInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const isConfirmValid = confirmationInput === "CONFIRM" && passwordInput;

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "250px",
        },
      }}
    >
      <DialogTitle>Confirm Account Deletion</DialogTitle>
      <DialogContent>
        <div className="d-flex gap-2 align-items-center mb-1">
          <Iconify
            icon="ion:warning-outline"
            marginBottom="2px"
            sx={{
              color: "#FF9800",
              height: "40px",
              width: "40px",
              flexShrink: 0,
            }}
          />
          <Typography>
            This action cannot be undone and your account along with all your
            account information, will be permanently deleted.
          </Typography>
        </div>

        {/* Password Input  ---------------------*/}
        <div style={{ padding: "10px 0px" }}>
          <Typography
            sx={{ paddingLeft: "5px", fontSize: "10px", color: "gray" }}
          >
            Enter Your Password
          </Typography>
          <TextField
            fullWidth
            className="delete-account-input"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div>
          <Typography
            sx={{
              paddingLeft: "5px",
              fontSize: "10px",
              userSelect: "none",
              color: "gray",
            }}
          >
            Please type <span style={{ fontWeight: 500 }}>CONFIRM</span> in
            uppercase letters to proceed
          </Typography>
          <TextField
            fullWidth
            className="delete-account-input"
            variant="outlined"
            placeholder="CONFIRM"
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="text"
          color="error"
          onClick={() => {
            onAgree(passwordInput);
          }}
          loading={loading}
          disabled={!isConfirmValid}
        >
          Delete Account Permanently
        </LoadingButton>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
            setPasswordInput("");
            setConfirmationInput("");
          }}
        >
          Keep Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
