import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  AllTasksVariant,
  ButtonIcon,
  ButtonSelect,
  CircularLoader,
  CommentsDrawer,
  FiltersDrawer,
  Iconify,
  MuiDialog,
  MultiButtonSelect,
  NoData,
  Page,
  SearchBar,
  TaskCard,
  TaskDetailDrawer,
} from "src/components";
import {
  Container,
  Box,
  Typography,
  Card,
  Stack,
  Grid,
  IconButton,
  Button,
  Tooltip,
  Badge,
  Avatar,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { capitalCase } from "change-case";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import {
  get_month_first_day,
  get_month_last_day,
  handle_localSearch,
  upload_task_files,
} from "src/utils";
import {
  _add_task_comment,
  _all_tasks,
  _dashboard_tasks,
  _delete_task,
  _delete_task_comment,
  _edit_task,
  _task_comment_list,
  _update_task_comment,
  _update_task_end_date,
  _update_task_members,
  _update_task_priority,
  _update_task_status,
  _update_task_tags_in_task,
} from "src/DAL";
import { motion, AnimatePresence } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ALLOWED_TASK_FILES_WITH_ICONS,
  TASK_PRIORITY_OPTIONS,
  TASK_STATUS_OPTIONS,
} from "src/constants";

import { ALL_TASK_SORT_OPTIONS } from "src/constants";
import { isAfter, parseISO } from "date-fns";
import { useAppContext } from "src/hooks";
import TaskDetailDialog from "src/components/TaskDetailDrawer/TaskDetailDrawer";
import MuiButtonSelectWithSearch from "src/components/MuiButtonSelectWithSearch";
/* <<--------------------------------------------------------->> */
const AVATAR_PROPS = {
  width: 30,
  height: 30,
  fontSize: "12px",
};
/* <<--------------------------------------------------------->> */
function isStatusOpSelected(option) {
  let selected = false;
  let local = localStorage.getItem("filters");
  if (local) {
    const parsed = JSON.parse(local);
    let found = parsed.task_status.find(
      (item) => String(item) === String(option.value)
    );
    if (found) {
      selected = found.selected;
    }
    return selected;
  }
}
function isPriorityOpSelected(option) {
  let selected = false;
  let local = localStorage.getItem("filters");
  if (local) {
    const parsed = JSON.parse(local);
    let found = parsed.priority.find(
      (item) => String(item) === String(option.value)
    );
    if (found) {
      selected = found.selected;
    }
    return selected;
  }
}
const AllTasks = () => {
  const STATUS_OPS = [...TASK_STATUS_OPTIONS]
    // .filter((item) => {
    //   if (item.value === 0) {
    //     return item;
    //   }
    //   if (item.value === 2) {
    //     return item;
    //   }
    //   if (item.value === 3) {
    //     return item;
    //   }
    // })
    .filter((item) => item.value !== 5)
    .map((item) => {
      return {
        ...item,
        selected: isStatusOpSelected(item),
      };
    });
  const PRIORITY_OPS = [...TASK_PRIORITY_OPTIONS].map((item) => {
    return {
      ...item,
      selected: isPriorityOpSelected(item),
    };
  });
  const [STATUS_OPTIONS, SET_STATUS_OPTIONS] = useState([...STATUS_OPS]);
  const [PRIORITY_OPTIONS, SET_PRIORITY_OPTIONS] = useState([...PRIORITY_OPS]);
  const [projectOptions, setProjectOptions] = useState([]);
  const navigate = useNavigate();
  const under_mob = useMediaQuery("(max-width:446px)");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const params_sort_query = params.get("sort");
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loaders, setLoaders] = useState({ tasks: true });
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);
  const [filtersOpen, setFilersOpen] = useState(false);
  const [projectTeam, setProjectTeam] = useState([]);
  const [projectTags, setProjectTags] = useState([]);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [editingComment, setEditingComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState();
  const [editCommentType, setEditCommentType] = useState("");
  const [editingCommentId, setEditingCommentId] = useState("");
  const [delCommentOpen, setDelCommentOpen] = useState(false);
  const [taskCount, setTaskCount] = useState({
    all_tasks: 0,
    pending_tasks: 0,
    inprogress_tasks: 0,
    tasks_for_pagination: 0,
    verified: 0,
    cancelled: 0,
    completed: 0,
  });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(
    localStorage.getItem("filters")
      ? JSON.parse(localStorage.getItem("filters"))
      : {
          task_status: [],
          priority: [],
          search: "",
          project_ids: [],
          sort_status: "newest_first",
        }
  );
  const [taskSortOptionIndex, setTaskSortOptionIndex] = useState(() => {
    let index = 0;
    ALL_TASK_SORT_OPTIONS.map((item, i) => {
      if (String(item.value) === String(filters.sort_status).trim()) {
        index = i;
      }
    });
    return index;
  });
  const [processing, setProcessing] = useState(false);
  const [loadingComments, setLoadingComments] = useState(true);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [selectedTaskComments, setSelectedTaskComments] = useState([]);
  const { dispatch_get_user_profile, socketEmit } = useAppContext();
  const [newComment, setNewComment] = useState("");
  const [newCommentForDrawer, setNewCommentForDrawer] = useState({
    title: "",
    type: "0",
  });
  /* <<--------------------------------------------------------->> */
  const handleProjectSelect = async (options) => {
    console.log(options, "options");
      const updated = options.map((proj) => ({
      ...proj,
      selected: proj.selected, 
    }));
  
    let _selected = updated.filter(proj => proj.selected).map(proj => proj._id);
    setFilters((prev) => ({
      ...prev,
      project_ids: _selected,
    }));
  
    setProjectOptions(updated);
    const stringified = JSON.stringify(updated);
    localStorage.setItem("selected-filter-projects", stringified);
  };
  
  // const handleProjectSelect = async (_option, _index) => {
  //   console.log(_option,"options")
  //   console.log(_index,"options _index")
  //   const updated = [...projectOptions].map((proj) => {
  //     if (String(proj._id) === String(_option._id)) {
  //       return { ...proj, selected: !proj.selected };
  //     }
  //     return proj;
  //   });
  //   let found = filters.project_ids.find((id) => id == _option._id);
  //   if (found) {
  //     setFilters((prev) => {
  //       return {
  //         ...prev,
  //         project_ids: prev.project_ids.filter((id) => id !== _option._id),
  //       };
  //     });
  //   } else {
  //     setFilters((prev) => {
  //       return { ...prev, project_ids: [...prev.project_ids, _option._id] };
  //     });
  //   }
  //   setProjectOptions(updated);
  //   const stringified = JSON.stringify(updated);
  //   localStorage.setItem("selected-filter-projects", stringified);
  // };
  
  const handleOpenFilters = async () => {
    setFilersOpen(true);
  };
  const handleCloseFilters = async () => {
    setFilersOpen(false);
  };
  const handleFilterChange = (e, item, type) => {
    // const checked = e.target.checked;
    console.log(filters, item, "Acascascascascasc");
    if (type === "status") {
      let checked = filters.task_status.findIndex((val) => val == item.value);
      console.log(checked, "Acascascascascasc");
      if (checked >= 0) {
        setFilters((prev) => {
          return {
            ...prev,
            task_status: prev.task_status.filter((val) => val !== item.value),
          };
        });
      } else {
        setFilters((prev) => {
          return {
            ...prev,
            task_status: [...prev.task_status, item.value],
          };
        });
      }
      // const updated = [...STATUS_OPTIONS].map((_item) => {
      //   if (_item.value === item.value) {
      //     return { ..._item, selected: checked };
      //   }
      //   return _item;
      // });
      // SET_STATUS_OPTIONS(updated);
      // localStorage.setItem("all-task-status-checks", JSON.stringify(updated));
    }
    if (type === "priority") {
      // const updated = [...PRIORITY_OPTIONS].map((_item) => {
      //   if (_item.value === item.value) {
      //     return { ..._item, selected: checked };
      //   }
      //   return _item;
      // });
      // SET_PRIORITY_OPTIONS(updated);
      // localStorage.setItem("all-task-priority-checks", JSON.stringify(updated));
      let checked = filters.priority.findIndex((val) => val == item.value);
      if (checked >= 0) {
        setFilters((prev) => {
          return {
            ...prev,
            priority: prev.priority.filter((val) => val !== item.value),
          };
        });
      } else {
        setFilters((prev) => {
          return {
            ...prev,
            priority: [...prev.priority, item.value],
          };
        });
      }
    }
  };
  const handleClearFilters = async (type) => {
    console.log("clear all");
    // const updated_status = [...STATUS_OPTIONS].map((_item) => {
    //   return { ..._item, selected: false };
    // });

    // localStorage.setItem(
    //   "all-task-status-checks",
    //   JSON.stringify(updated_status)
    // );
    // const updated_priority = [...PRIORITY_OPTIONS].map((_item) => {
    //   return { ..._item, selected: false };
    // });
    // localStorage.setItem(
    //   "all-task-priority-checks",
    //   JSON.stringify(updated_status)
    // );
    // SET_STATUS_OPTIONS(updated_status);
    // SET_PRIORITY_OPTIONS(updated_priority);
    setFilters((prev) => {
      if (type === "task_status") {
        return { ...prev, task_status: [] };
      } else if (type === "priority") {
        return { ...prev, priority: [] };
      } else {
        return { ...prev, task_status: [], priority: [] }; // Clear all if type is "all"
      }
    });
  };

  const getSelectedProjects = () => {
    let selected = [];
    projectOptions.map((item) => {
      let index = filters.project_ids.findIndex((id) => id == item._id);
      if (index !== -1) {
        selected.push(item);
      }
    });

    return selected;
  };
  const getAppliedFilters = () => {
    // let applied = [];
    // STATUS_OPTIONS.map((item) => {
    //   if (item.selected) {
    //     applied.push(item);
    //   }
    // });
    // PRIORITY_OPTIONS.map((item) => {
    //   if (item.selected) {
    //     applied.push(item);
    //   }
    // });
    // return applied;
    return filters.task_status.length + filters.priority.length;
  };

  const handleNavBack = async () => {
    navigate(-1);
  };
  const openCommentsDrawer = () => {
    setCommentsOpen(true);
  };
  const closeCommentsDrawer = () => {
    setCommentsOpen(false);
    setSelectedTaskComments([]);
  };
  const openDelTaskDialog = () => {
    setDelDialogOpen(true);
  };
  const closeDelTaskDialog = () => {
    setProcessing(false);
    setDelDialogOpen(false);
  };

  const onSetSelectedTask = (task) => {
    console.log(task);
  };
  const onOpenTaskDetail = (task) => {
    console.log(task, "task-");
    setSelectedTask(task);
  };
  const onOpenComments = (task) => {
    setSelectedTask(task);
    getTaskComments(task);
    openCommentsDrawer();
    setTasks((prev) => {
      return prev.map((tsk) => {
        if (tsk._id == task._id) {
          return { ...tsk, task_comment_unread_count: 0 };
        } else {
          return { ...tsk };
        }
      });
    });
  };
  const closeDelCommentDialog = () => {
    setDelCommentOpen(false);
    setSelectedComment();
  };
  const openDelCommentDialog = (comment) => {
    setSelectedComment(comment);
    setDelCommentOpen(true);
  };
  const getTaskComments = async (task, noLoader) => {
    if (!noLoader) {
      setLoadingComments(true);
    }
    const data = {
      workspace_id: task.workspace_id,
    };
    const result = await _task_comment_list(task._id);
    console.log(result, "com-list");
    setLoadingComments(false);
    setSelectedTaskComments(result.project_list.comment_list);
  };
  const handleCommentAction = async (action, payload) => {
    console.log(payload, "payload");
    console.log(action, "action");
    if (action === "add_comment") {
      return handleAddComment(payload);
    }
    if (action === "edit_comment") {
      setSelectedComment(payload);
      return handleEditComment(payload);
    }
    if (action === "delete_comment") {
      setSelectedComment(payload);
      return handleDeleteComment(payload);
    }
  };
  const afterCommentAction = () => {
    getDashboardData();
    getTaskComments(selectedTask, true);
    setSelectedComment();
    setEditingComment(false);
    setEditCommentTitle("");
    setEditingCommentId("");
  };
  const handleAddComment = async (payload) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }
    const { title, type } = payload;
    const data = {
      comment_title: title.trim().replace(/<a /g, "<a target='_blank' "),
      comment_type: type,
    };

    console.log(data, "req-add-comm");
    const result = await _add_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      setSelectedTask((prev) => {
        let found = prev.task_comment.findIndex(
          (item) => item._id == result.task_comment._id
        );
        if (found !== -1) {
          let temp = prev.task_comment;
          temp.splice(found, 1);
          temp.push(result.task_comment);
          return {
            ...prev,
            task_comment: [...temp],
          };
        } else {
          return {
            ...prev,
            task_comment: [...prev.task_comment, result.task_comment],
          };
        }
        // return { ...prev };
      });
      if (type == "0") {
        // let receiver = [selectedTask.creator_profile.user_id];
        // selectedTask.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id } = selectedTask;
        socketEmit("send_notification_to_all", {
          receiver: result.task_comment.allow_members,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } added a comment in task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    }
    enqueueSnackbar(result.message, {
      variant: result.code == 200 ? "success" : "error",
    });
    return result;
  };

  const handleEditComment = async (selectedComment) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }
    console.log(selectedComment, "asuyaiosuioasdjasodi");
    const data = {
      comment_id: selectedComment._id,
      comment_title: selectedComment.comment_title,
      comment_type: selectedComment.comment_type,
    };
    console.log(data, "req-update-com");
    const result = await _update_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      setSelectedTask((prev) => {
        return {
          ...prev,
          task_comment: prev.task_comment.map((oldComment) => {
            if (oldComment._id === result.task_comment._id) {
              return { ...result.task_comment };
            } else {
              return { ...oldComment };
            }
          }),
        };
      });
    }
    return result;
  };
  const handleDeleteComment = async (payload) => {
    if (!selectedTask) {
      return undefined;
    }
    let req_obj;
    if (payload) {
      req_obj = { comment_id: payload._id };
    } else if (selectedComment) {
      req_obj = { comment_id: selectedComment._id };
    }
    const result = await _delete_task_comment(req_obj, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      setSelectedTask((prev) => {
        return {
          ...prev,
          task_comment: prev.task_comment.filter((comment) =>
            comment._id !== selectedComment ? selectedComment._id : payload._id
          ),
        };
      });
      closeDelCommentDialog();
    }
    return result;
  };
  const getDashboardData = async () => {
    // let first_day = get_month_first_day();
    // let last_day = get_month_last_day();
    // const data = { start_date: first_day, end_date: last_day };

    const result = await _all_tasks(page, 40, filters);
    if (result.code === 200) {
      const getModified = (data) => {
        let modified = data.map((item) => {
          return { ...item, ["query_field"]: item.task_title };
        });
        console.log(modified, "modi");
        return modified;
      };
      console.log(result, "dashboard-res");
      const { project_list } = result;
      setTasks(getModified(project_list.all_task_list));
      setTaskCount({
        all_tasks: project_list.total_task_count,
        tasks_for_pagination: project_list.all_task_count,
        pending_tasks: project_list.pending_task_count,
        inprogress_tasks: project_list.in_progress_task_count,
        verified: project_list.verified_task_count,
        completed: project_list.complete_task_count,
        cancelled: project_list.cancled_task_count,
      });
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
      const isProjSelected = (proj) => {
        let selected = false;
        let local = localStorage.getItem("filters");
        if (local) {
          let parsed = JSON.parse(local);
          let found = parsed.project_ids.find(
            (item) => String(item) === String(proj._id)
          );
          if (found) {
            selected = true;
          }
        }
        return selected;
      };
      const project_options = project_list.all_task_projects.map((item) => {
        return {
          ...item,
          selected: isProjSelected(item),
          label: item.title,
          value: item,
        };
      });
      setProjectOptions(project_options);
    } else {
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenTaskDetail = (_task) => {
    setSelectedTask(_task);
    setTaskDetailOpen(true);
  };
  const handleCloseTaskDetail = () => {
    setSelectedTask(undefined);
    setTaskDetailOpen(false);
  };
  const handleCopyTaskLink = async (task) => {
    const { project, section_id, _id } = task;
    console.log(task, "to-copy");
    const scroll_task_data = {
      task_id: _id,
      section_id: section_id,
    };
    let newLink = "";
    newLink = `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`;
    console.log(newLink);
    // unsecuredCopyToClipboard(newLink);
    let has_error = "";
    try {
      await window.navigator.clipboard.writeText(newLink);
    } catch (error) {
      if (error) {
        if (error.message) {
          has_error = error.message;
        }
      }
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    if (has_error === "") {
      enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
      return;
    }
    enqueueSnackbar("Could not copy task link", { variant: "error" });
  };
  const handleAction = (action, data) => {
    console.log(action, "action");
    console.log(data, "payload");
    if (action === "change_status") {
      handleChangeStatus(data);
    }
    if (action === "change_date") {
      handleChangeDate(data);
    }
    if (action === "change_priority") {
      handleChangePriority(data);
    }
    if (action === "change_members") {
      handleChangeMembers(data);
    }
    if (action === "del_task") {
      handleDelTask(data);
    }
    if (action === "copy_task_link") {
      handleCopyTaskLink(data);
    }
    if (action === "open_task_detail") {
      handleOpenTaskDetail(data);
    }
  };

  const handleChangeStatus = async (data) => {
    console.log(data, "change-status");
    const { is_checkbox, status, checked, task } = data;

    const updateTaskStatus = async (req_data, section_id) => {
      const result = await _update_task_status(req_data, section_id);
      getDashboardData();
      if (result.code === 200) {
        taskDetailOpen && setSelectedTask(result.task_detail);
        enqueueSnackbar("Task Status Updated Successfully", {
          variant: "success",
        });
        // let receiver = [result.task_detail.creator_profile.user_id];
        // result.task_detail.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.allow_members,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.hidden_members,
          data: result.Project,
          message: `A member updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    if (is_checkbox === false) {
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }

    if (is_checkbox === true) {
      let updated_status = checked === true ? 3 : 0;
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: updated_status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: updated_status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }
  };

  const handleChangePriority = async (data) => {
    console.log(data, "change-priority");
    const { priority, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, priority: priority };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      task_priority: priority,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_priority(req_data, task.section_id);

    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      console.log(result, "res");
      enqueueSnackbar("Task Priority Updated Successfully", {
        variant: "success",
      });
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.hidden_members,
        data: result.Project,
        message: `A member updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
    getDashboardData();
  };
  const onEditComment = (_comment) => {
    setSelectedComment(_comment);
    setEditingComment(true);
    setEditCommentTitle(_comment.comment_title);
    setEditCommentType(String(_comment.comment_type));
  };
  const handleChangeDate = async (data) => {
    console.log(data, "change-date");
    const { endDate, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, end_date: endDate };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      end_date: endDate,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_end_date(req_data, task.section_id);
    getDashboardData();
    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      console.log(result, "res");
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the target date of the task`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };
  const handleDelTask = async (task) => {
    setSelectedTask(task);
    openDelTaskDialog();
  };
  const deleteTask = async () => {
    console.log(selectedTask, "slc task");
    if (!selectedTask) {
      return;
    }
    setProcessing(true);
    const result = await _delete_task(selectedTask._id);
    getDashboardData();
    if (result.code === 200) {
      setTasks((prev) => prev.filter((item) => item._id != selectedTask._id));
      closeDelTaskDialog();
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      closeDelTaskDialog();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeMembers = async (data) => {
    console.log(data, "change-team");
    const { task, taskTeam, isSelected, targetUser, action } = data;

    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user_id) !== String(targetUser.user_id)
        );
      }
    }

    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };

    setTasks((prev) =>
      prev.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      })
    );
    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(user);
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      workspace_id: task.workspace_id,
      list_type: "0",
    };

    const result = await _update_task_members(req_data);
    getDashboardData();
    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        const { project, section_id, _id } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleTaskSortClick = (option, i) => {
    console.log(option, i);
    setTaskSortOptionIndex(i);
    setFilters((prev) => {
      return {
        ...prev,
        sort_status: option.value,
      };
    });
  };

  const newestFirstSorter = (a, b) => {
    const a_created_at = a.created_at;
    const b_created_at = b.created_at;
    const a_after_b = isAfter(parseISO(a_created_at), parseISO(b_created_at));
    if (!a_created_at || a_created_at === "") {
      return 1;
    }
    if (a_after_b === true) {
      return 1;
    }
    if (a_after_b === false) {
      return -1;
    }
    return 0;
  };
  const oldestFirstSorter = (a, b) => {
    const a_created_at = a.created_at;
    const b_created_at = b.created_at;
    const a_after_b = isAfter(parseISO(a_created_at), parseISO(b_created_at));
    if (!a_created_at || a_created_at === "") {
      return 1;
    }
    if (a_after_b === true) {
      return -1;
    }
    if (a_after_b === false) {
      return 1;
    }
    return 0;
  };
  const dueDateSorter = (a, b) => {
    const a_end_at = a.end_date;
    const b_end_at = b.end_date;
    const a_after_b = isAfter(parseISO(a_end_at), parseISO(b_end_at));
    if (!a_end_at || a_end_at === "") {
      return 1;
    }
    if (a_after_b === true) {
      return 1;
    }
    if (a_after_b === false) {
      return -1;
    }
    return 0;
  };
  const priorityHighToLowSorter = (a, b) => {
    const a_priority = a.priority;
    const b_priority = b.priority;
    const a_after_b = Number(a_priority) > Number(b_priority);

    if (!a_priority || a_priority === "") {
      return 1;
    }
    if (a_after_b === true) {
      return 1;
    }
    if (a_after_b === false) {
      return -1;
    }
    return 0;
  };
  const priorityLowToHighSorter = (a, b) => {
    const a_priority = a.priority;
    const b_priority = b.priority;
    const a_after_b = Number(a_priority) > Number(b_priority);
    if (!a_priority || a_priority === "") {
      return 1;
    }
    if (a_after_b === true) {
      return -1;
    }
    if (a_after_b === false) {
      return 1;
    }
    return 0;
  };

  const handleSort = (a, b) => {
    let condition = ALL_TASK_SORT_OPTIONS[taskSortOptionIndex].value;
    if (condition === "newest_first") {
      return newestFirstSorter(a, b);
    }
    if (condition === "oldest_first") {
      return oldestFirstSorter(a, b);
    }
    if (condition === "priority_high_to_low") {
      return priorityHighToLowSorter(a, b);
    }
    if (condition === "due_date") {
      return dueDateSorter(a, b);
    }
    if (condition === "priority_low_to_high") {
      return priorityLowToHighSorter(a, b);
    }
  };

  console.log(params_sort_query, "params_sort_query");
  const applied_filters = getAppliedFilters();
  const selected_projects = getSelectedProjects();
  const getFilteredTasks = () => {
    let filtered_tasks = [];
    let modified_tasks = [...tasks].map((item) => {
      return { ...item, pushed: false };
    });

    let found_status = applied_filters.find(
      (item) => String(item.option_type) === "status" && item.selected === true
    );

    let found_priority = applied_filters.find(
      (item) =>
        String(item.option_type) === "priority" && item.selected === true
    );

    if (!found_status && !found_priority) {
      filtered_tasks = [...tasks];
    }
    if (found_status && found_priority) {
      modified_tasks.map((task) => {
        let allow_by_priority = false;
        let allow_by_status = false;
        PRIORITY_OPTIONS.map((priority) => {
          if (priority.selected) {
            if (String(priority.value) === String(task.priority)) {
              allow_by_priority = true;
            }
          }
        });
        STATUS_OPTIONS.map((status) => {
          if (status.selected) {
            if (String(status.value) === String(task.task_status)) {
              allow_by_status = true;
            }
          }
        });

        if (allow_by_priority && allow_by_status) {
          filtered_tasks.push(task);
        }
      });
    }
    if (!found_status && found_priority) {
      modified_tasks.map((task) => {
        let allow = false;
        PRIORITY_OPTIONS.map((priority, index) => {
          if (priority.selected) {
            if (String(priority.value) === String(task.priority)) {
              allow = true;
            }
          }
        });

        if (allow) {
          filtered_tasks.push(task);
        }
      });
    }
    if (found_status && !found_priority) {
      modified_tasks.map((task) => {
        let allow = false;
        STATUS_OPTIONS.map((status) => {
          if (status.selected) {
            if (String(status.value) === String(task.task_status)) {
              allow = true;
            }
          }
        });

        if (allow) {
          filtered_tasks.push(task);
        }
      });
    }
    const unsorted_tasks =
      applied_filters.length === 0 ? [...tasks] : [...filtered_tasks];

    const sorted_tasks = unsorted_tasks.sort((a, b) => handleSort(a, b));
    let filter_by_projects = [...sorted_tasks];
    if (selected_projects.length > 0) {
      filter_by_projects = [...sorted_tasks].filter((task) => {
        let found = selected_projects.find(
          (proj) => String(proj._id) === String(task.project.id)
        );
        if (found) {
          return task;
        }
      });
    }
    return handle_localSearch(search, filter_by_projects);
  };
  const handleTaskDetailSidebarAction = async (_action, _payload) => {
    console.log(_action, "action--");
    console.log(_payload, "payload--");
    if (String(_action) === "remove_task_file") {
      setSelectedTask(_payload);
    }
    if (String(_action) === "edit_task") {
      return handleEditTask(_payload);
    }
  };
  const uploader = async (addTaskFiles) => {
    if (addTaskFiles.length === 0) {
      console.log("no files");
      return [];
    }
    let paths = [];
    for (let i = 0; i < addTaskFiles.length; i++) {
      let res = await upload_task_files(addTaskFiles[i].file);
      paths.push(res.path);
    }
    return paths;
  };
  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };
  const handleEditTask = async (_data) => {
    const { selectedTaskDescription, selectedTaskTitle, addTaskFiles } = _data;
    let target_task = currentTask();
    console.log(target_task);
    let task_file_paths = [...target_task.image];

    let image_paths = await uploader(addTaskFiles);
    if (image_paths) {
      console.log("file-paths", image_paths);
      task_file_paths = [...target_task.image, ...image_paths];
    }
    console.log(task_file_paths, "final-paths");
    const req_data = {
      task_id: target_task._id,
      workspace_id: target_task.workspace_id,
      image: task_file_paths /* include current task paths too */,
      task_title: selectedTaskTitle,
      task_description: selectedTaskDescription,
    };
    console.log(req_data, "edit-req");
    let success = false;
    const result = await _edit_task(req_data, target_task.section_id);
    if (result.code === 200) {
      success = true;

      const { task_data } = result;
      let updated_task = {
        ...task_data,
        section_id: target_task.section_id,
        query_field: task_data.task_title,
        workspace_id: target_task.workspace_id,
      };

      setTasks((_tasks) =>
        _tasks.map((task) => {
          if (String(task._id) === String(target_task._id)) {
            return updated_task;
          }
          return task;
        })
      );
      setSelectedTask(updated_task);
      // getProjectTasks();
      console.log(result, "edit-task-res");
      enqueueSnackbar("Task Updated Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    return success;
  };
  const pending_tasks = tasks.filter(
    (item) => String(item.task_status) === "0"
  );
  const in_progress_tasks = tasks.filter(
    (item) => String(item.task_status) === "2"
  );

  const onChangeTags = async (data) => {
    try {
      const result = await _update_task_tags_in_task(data);
      if (result.code == 200) {
        setSelectedTask(result.task_data);
        setTasks((prev) => {
          return prev.map((task) => {
            if (task._id == result.task_data._id) {
              return { ...result.task_data };
            } else {
              return { ...task };
            }
          });
        });
      }
    } catch (error) {
      console.log(error, " catched while updating task tags");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  ////==============Files///////////////////////////////////////////
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = currentTask();
    const { image } = task;
    for (let path of image) {
      let data = getFileIconType(path);
      const { file_type, file_path, color } = data;
      files.push({
        path: file_path,
        type: file_type,
        db_path: path,
        color: color,
      });
    }
    return files;
  };

  /* <<--------------------------------------------------------->> */
  const getPaginationCount = () => {
    let pages = taskCount.tasks_for_pagination / 40;
    let pagesFloor = Math.floor(pages);
    if (pages > pagesFloor) {
      return pagesFloor + 1;
    } else {
      return pagesFloor;
    }
  };
  useEffect(() => {
    if (selectedTask) {
      setProjectTeam(
        projectOptions.find((project) => project._id == selectedTask.project.id)
          ?.team
      );
      setProjectTags(
        projectOptions.find((project) => project._id == selectedTask.project.id)
          ?.tag_colors
      );
    }
  }, [selectedTask]);
  /* <<--------------------------------------------------------->> */
  // useEffect(() => {
  //   setFilters((prev) => {
  //     return {
  //       ...prev,
  //       task_status: STATUS_OPTIONS.filter((option) => option.selected).map(
  //         (option) => option.value
  //       ),
  //       priority: PRIORITY_OPTIONS.filter((option) => option.selected).map(
  //         (option) => option.value
  //       ),
  //     };
  //   });
  // }, [STATUS_OPTIONS, PRIORITY_OPTIONS]);
  useEffect(() => {
    setPage(0);
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);
  useLayoutEffect(() => {
    getDashboardData();
    return () => {
      // SET_STATUS_OPTIONS([...STATUS_OPS]);
      // SET_PRIORITY_OPTIONS([...PRIORITY_OPS]);
      setTasks([]);
      setLoaders({ tasks: true });
    };
  }, [page, filters]);
  return (
    <div>
      <Page title="All Tasks">
        <Container maxWidth="xl">
          <Grid container className="project-header">
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              display="flex"
              alignItems="center"
            >
              <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
                <Iconify icon="ep:back" />
              </IconButton>
              <Typography fontWeight="bold">
                {capitalCase("all tasks")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Stack
                justifyContent="flex-end"
                direction={`${under_mob ? "column" : "row"}`}
                flexWrap={"wrap"}
                spacing={2}
                sx={{ mt: under_mob ? 2 : 0, pr: 1 }}
              >
                {/* <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>Active Projects</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="Remy Sharp"
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {projectOptions.length}
                    </Avatar>
                  )}
                </Stack> */}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>Pending</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="0"
                      sx={{
                        bgcolor: (theme) => theme.palette.warning.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {taskCount.pending_tasks}
                    </Avatar>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>In Progress</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="0"
                      sx={{
                        bgcolor: (theme) => theme.palette.info.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {taskCount.inprogress_tasks}
                    </Avatar>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>Completed</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="0"
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {taskCount.completed}
                    </Avatar>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>Verified</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="0"
                      sx={{
                        bgcolor: (theme) => theme.palette.inactive.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {taskCount.verified}
                    </Avatar>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>Canceled</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="0"
                      sx={{
                        bgcolor: (theme) => theme.palette.error.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {taskCount.cancelled}
                    </Avatar>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.3}
                >
                  <Typography>Total</Typography>
                  {loaders.tasks ? (
                    <CircularProgress size={AVATAR_PROPS.width} />
                  ) : (
                    <Avatar
                      alt="0"
                      sx={{
                        bgcolor: (theme) => theme.palette.inactive.main,
                        ...AVATAR_PROPS,
                      }}
                    >
                      {taskCount.all_tasks > 99 ? "99+" : taskCount.all_tasks}
                    </Avatar>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Stack sx={{ my: 3 }} direction="row" alignItems="center">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={12} sm={12} md={4}>
                <SearchBar
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setFilters((prev) => {
                      return {
                        ...prev,
                        search: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Stack
                  direction={`${under_mob ? "column" : "row"}`}
                  spacing={1}
                  sx={{ mt: under_mob ? 2 : 0 }}
                >
                  <div>
                    <Tooltip title="Filters">
                      <Badge
                        color="primary"
                        overlap="circular"
                        badgeContent={applied_filters}
                        variant="standard"
                        sx={{
                          "& .MuiBadge-badge": {
                            top: "0px",
                            right: "6px",
                          },
                        }}
                      >
                        <ButtonIcon
                          title="Filters"
                          endIcon={"fluent:filter-28-filled"}
                          onClick={handleOpenFilters}
                        />
                      </Badge>
                    </Tooltip>
                  </div>
                  <MuiButtonSelectWithSearch // ---- Shamroz
                    options={projectOptions}
                    title="Project"
                    onItemClick={handleProjectSelect}
                  />

                  {/* <MultiButtonSelect
                    options={projectOptions}
                    title="Project"
                    onItemClick={handleProjectSelect}
                  />
                  */}
                  <ButtonSelect
                    options={ALL_TASK_SORT_OPTIONS}
                    title="Sort by"
                    onFilterOptionClick={handleTaskSortClick}
                    selectedIndex={taskSortOptionIndex}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          {!loaders.tasks && (
            /* limit 10 on dashboard*/
            <>
              <Stack spacing={2}>
                <AnimatePresence layout initial={false}>
                  {tasks.map((task, i) => (
                    <motion.div
                      key={task._id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      layout
                    >
                      <AllTasksVariant
                        task={task}
                        onSetSelectedTask={onSetSelectedTask}
                        taskTeam={task.team}
                        onOpenDetail={onOpenTaskDetail}
                        onOpenComments={onOpenComments}
                        onTaskAction={handleAction}
                        projects={projectOptions}
                        getListing={() => getDashboardData()}
                        onChangeTags={onChangeTags}
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Stack>
              {taskCount.tasks_for_pagination > 40 && (
                <div className="d-flex justify-content-center my-4">
                  <Pagination
                    page={page + 1}
                    count={getPaginationCount()}
                    color="primary"
                    size="large"
                    onChange={(e, p) => setPage(p - 1)}
                    hidePrevButton={page == 0}
                    hideNextButton={page == getPaginationCount() - 1}
                  />
                </div>
              )}
            </>
          )}
          {loaders.tasks && <CircularLoader />}
        </Container>
        {/* <TaskDetailDrawer
          selectedTask={selectedTask}
          open={taskDetailOpen}
          onClose={handleCloseTaskDetail}
          disabled={false}
          onAction={handleTaskDetailSidebarAction}
        /> */}
        {currentTask() && (
          <TaskDetailDialog
            open={taskDetailOpen}
            onClose={handleCloseTaskDetail}
            task={selectedTask}
            setTask={setSelectedTask}
            taskFiles={getTaskImages()}
            getFileIconType={getFileIconType}
            projectTeam={projectTeam}
            projectTags={projectTags}
            setProjectTags={setProjectTags}
            onChangeTags={onChangeTags}
            onChangePriority={handleChangePriority}
            onChangeStatus={handleChangeStatus}
            onChangeMembers={handleChangeMembers}
            handleSendComment={() =>
              handleAddComment({ title: newComment, type: 0 })
            }
            handleUpdateTaskComment={(e, type, comment) =>
              handleEditComment({ ...comment, comment_title: editCommentTitle })
            }
            newComment={newComment}
            setNewComment={setNewComment}
            onDelComment={openDelCommentDialog}
            editCommentTitle={editCommentTitle}
            setEditCommentTitle={setEditCommentTitle}
            onEditComment={onEditComment}
            editingCommentId={editingCommentId}
            setEditingCommentId={setEditingCommentId}
            setTasks={(section_id, task_id, updated_task) => {
              setTasks((prev) => {
                return prev.map((oldTask) => {
                  if (oldTask._id == task_id) {
                    return { ...updated_task };
                  } else {
                    return { ...oldTask };
                  }
                });
              });
            }}
            onChangeDate={handleChangeDate}
            getListing={getDashboardData}
          />
        )}
        <CommentsDrawer
          open={commentsOpen}
          selectedTask={selectedTask}
          onClose={closeCommentsDrawer}
          comments={selectedTaskComments}
          disabled={false}
          onCommentAction={handleCommentAction}
          loading={loadingComments}
          newComment={newCommentForDrawer}
          setNewComment={setNewCommentForDrawer}
        />
        <FiltersDrawer
          clearFilter={handleClearFilters}
          open={filtersOpen}
          selectedFilters={filters}
          onClose={handleCloseFilters}
          priorityFilters={PRIORITY_OPTIONS}
          statusFilters={STATUS_OPTIONS}
          onChange={handleFilterChange}
        />
        <MuiDialog
          open={delDialogOpen}
          onAgree={deleteTask}
          onToggle={closeDelTaskDialog}
          title="Delete Task"
          loading={processing}
          message="Are you sure you want to delete this task?"
        />
        <MuiDialog
          open={delCommentOpen}
          onToggle={closeDelCommentDialog}
          onAgree={handleDeleteComment}
          loading={false}
          title="Delete Comment"
          message={`Are you sure you want to delete this comment?`}
        />
        {/* <div className="no-data-container"> */}
        <NoData
          title="No Tasks Found"
          loading={loaders.tasks}
          dataLength={taskCount.tasks_for_pagination}
        />
        {/* </div> */}
      </Page>
    </div>
  );
};

export default AllTasks;
