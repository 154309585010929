import { get_from_localStorage, invokeApi } from "src/utils";

export const _user_login = async (data) => {
  const token = await _generate_token();
  const requestObj = {
    path: `api/app_api/login`,
    method: "POST",
    headers: {
      "x-sh-token": token.token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _user_logout = async (token) => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};
export const _user_logout_from_all_devices = async (token) => {
  const requestObj = {
    path: `api/app_api/logout_from_all_devices`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};

export const _user_signup = async (data) => {
  const token = await _generate_token();
  const requestObj = {
    path: `api/customer/signup_customer`,
    method: "POST",
    headers: {
      "x-sh-token": token.token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _user_password_reset = async (data) => {
  const requestObj = {
    path: `api/app_api/reset_password`,
    method: "POST",
    postData: data,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _user_password_change = async (data) => {
  const requestObj = {
    path: `api/app_api/change_password`,
    method: "PUT",
    postData: data,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _user_profile = async (data) => {
  const requestObj = {
    path: `api/team/team_member_profile`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_user_profile = async (data, id) => {
  const requestObj = {
    path: `api/customer/edit_customer/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _send_email_reset_code = async (data) => {
  const requestObj = {
    path: `api/app_api/email_verification`,

    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _send_code_for_email_change = async (data) => {
  const requestObj = {
    path: `api/app_api/email_verification_for_email_update`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _verify_email_reset_code = async (data) => {
  const requestObj = {
    path: `api/app_api/code_verification`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _change_user_email = async (data) => {
  const requestObj = {
    path: `api/app_api/change_email`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _generate_token = () => {
  const requestObj = {
    path: `api/app_api/send_verification_token`,
    method: "POST",
    postData: { code: "verify" },
  };
  return invokeApi(requestObj);
};
export const _verify_email = async (data) => {
  const token = await _generate_token();
  const requestObj = {
    path: `api/app_api/code_verification_email`,
    method: "POST",
    headers: {
      "x-sh-token": token.token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _resend_code = async (data) => {
  const token = await _generate_token();
  const requestObj = {
    path: `api/app_api/resend_code`,
    method: "POST",
    headers: {
      "x-sh-token": token.token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_account = async (id , data) => {
  const requestObj = {
    path: `api/customer/delete_customer/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _google_login = (data) => {
  const requestObj = {
    path: `api/customer/add_google_account`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _google_logout = () => {
  const requestObj = {
    path: `api/customer/remove_google_account`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_user_profile = () => {
  const requestObj = {
    path: `api/customer/customer_detail`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
